.security-section {
  padding: 100px 0;

  .security-content {
    display: flex;
    flex-direction: column;

    .security-header {
      p.body-48-bold {
        margin-bottom: 20px;
      }
    }

    .security-content {
      display: flex;
      flex-direction: row;
      margin-top: 60px;
      justify-content: space-between;

      .left-section {
        padding: 40px 30px 185px;
        background: #92B4F6;
        border-radius: 10px;
        width: 527px;
        position: relative;

        p.h4 {
          margin-top: 30px;
        }

        img {
          position: absolute;
          top: 233px;
          right: -151px;
        }

        .small {
          display: none;
        }
      }

      .right-section {
        display: flex;
        flex-direction: column;


        .row-card {
          width: 746px;
          padding: 40px 30px;

          &.first-row {
            margin-bottom: 25px;
            background: #EEF4FF;
            border-radius: 10px;

            p.body-32-bold {
              color: #48556E;
              margin-bottom: 30px;
            }

            p.h4 {
              color: #48556E;
            }

          }

          &.second-row {
            background: rgba(233, 240, 253, 0.3);
            border-radius: 10px;

            p.body-32-bold {
              color: #1849A9;
              margin-bottom: 30px;
            }

            p.h4 {
              color: #1849A9;
            }

          }
        }
      }
    }

  }
}
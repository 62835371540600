.header {
  padding: 26px 26px;
  background: rgba(233, 240, 253, 0.3);
  backdrop-filter: blur(7.5px);

  .logo img {
    margin-right: 14px;
  }

  .container {
    flex-wrap: wrap;

    a {
      text-decoration: none;
      cursor: pointer;
    }

    a:hover {
      background-color: transparent;
      color: #172133;
    }

    .btn__default-primary:hover {
      background-color: #4482FF;
      color: transparent;
    }

    .language_container {
      a {
        padding: 4px 16px;
        background-color: transparent;
        color: #707A83;
      }

      .active {
        background: #EEF4FF;
        border-radius: 8px;
        color: #4482FF;
      }
    }

  }
}

.navigation-menu {
  height: 24px;
  width: 24px;
  margin: auto 0 auto auto;
  display: none;
}

.offcanvas-end {
  box-shadow: 0 5px 10px 0 #301F851A;
  border: 0;
  width: 285px!important;
}

.offcanvas-body {
  box-shadow: 0 5px 10px 0 #301F851A;
  border: 0;
  padding: 34px 20px;

  .navigation-menu {
    margin-bottom: 20px;
  }

  .mobile-navigation {
    .navigation-link {
      text-decoration: none;
      margin-bottom: 24px;
    }
  }
}





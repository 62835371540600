.join-section {
  background: #F8FBFE;
  padding: 132px 0 169px 0;

  .body-48-bold {
   margin-bottom: 56px;
  }

  a {
    width: 368px;

    span {
      margin: auto;
    }
  }
}
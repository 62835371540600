.tariff-section {
  padding: 100px 0;

  .tariff-content {

    .flex-section {
      flex-direction: row;
      justify-content: space-between;
      margin-top: 60px;

      .left-section {

        .tariff_mini-card {
          transition-duration: 1s;
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 660px;
          padding: 24px 19px 24px;
          background: rgba(233, 240, 253, 0.3);
          border-radius: 24px;
          margin-bottom: 20px;


          img {
            margin-right: 30px;
          }

          .radio-icon {
            margin: auto 0 auto auto;
            width: 24px;
            height: 24px;
            background: url("../images/default-radio.svg");
          }
        }

        .tariff_mini-card:hover {
          box-shadow: 0 15px 50px rgba(80, 134, 224, 0.2);
          transition-duration: 1s;
        }

        .tariff_mini-card:last-child {
          margin-bottom: 0;

          .text-content {

            .body-15-bold {
              color: #DF2059;
            }
          }
        }

        .active {
          background: #FFFFFF;
          box-shadow: 0 15px 50px rgba(80, 134, 224, 0.2);

          .radio-icon {
            margin: auto 0 auto auto;
            width: 24px;
            height: 24px;
            background: url("../images/active-radio.svg");
          }
        }
      }

      .right-section {

        .tariff-card {
          width: 416px;
          height: 685px;
          background: #FFFFFF;
          box-shadow: 0 15px 50px rgba(80, 134, 224, 0.2);
          border-radius: 24px;
          padding: 24px;
          flex-direction: column;

          .tariff-card-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
          }

          .card-header {
            flex-direction: row;

            img {
              margin-right: 32px;
            }

            p {
              margin: auto 0;
            }
          }

          .tariff-divider {
            width: 100%;
            height: 5px;
            background-color: #92B4F6;
            border-radius: 21px;
            margin-top: 30px;
            margin-bottom: 20px;
          }

          a span {
            margin: auto;
          }

          .property-container {
            display: flex;
            flex-direction: column;

            .tariff-property {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 16px;

              p:first-child {
                margin-right: 16px;
              }

              .line-separator {
                border-top: 1px dashed #D4D9E2;
                height: 0;
                flex: 1
              }

              p:last-child {
                margin-left: 16px;
              }

              .big {
                margin-left: auto!important;
              }
            }
          }

          .color-green {
            margin-bottom: 16px;
          }


          .sum-container {
            margin-top: 42px;

            p {
              text-align: center;
            }
          }

          &.disabled {

            .tariff-card-content {

              .card-header {

                .color-500 {
                 color: #707A83;
                }
              }

              .tariff-divider {
                background-color: #D4D9E2;;
              }

              .btn__default-primary {
                background: #D4D9E2;
                border: 1px solid #D4D9E2;
              }
            }
          }

        }

      }
    }
  }
}
.what-vidi-section {
  background: #F8FBFE;
  padding: 100px 0;
  background-image: url("../images/what_vidi_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;

  .container {

    .body-18-bold {
      margin: 20px auto 60px;
    }

    img {
      width: 567px;
      height: 532px;
    }
  }
}
.landing-footer {
  background-color: #4482FF;

  .container {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 56px;
    padding-bottom: 72px;

    .left-section {
      width: 282px;

      div {
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          margin-right: 12px;
        }
      }

      a {
        margin-top: 10px;
        text-decoration: none;

        img {
          margin-right: 5px;
        }
      }

      a:hover {
        background-color: unset !important;
        color: #FFFFFF;
      }

      .company-name {
        text-transform: uppercase;
        margin: 14px 0;
      }
    }

    .right-section {
      width: 270px;

      .footer-social {
        display: flex;
        flex-direction: row;
        align-items: center;

        a {
          text-decoration: none;
          cursor: pointer;
          margin-right: 15px;
        }

        a:hover {
          background-color: unset !important;
          color: #FFFFFF;
        }
      }

      .docs-container {
        margin-top: 16px;

        .doc-el {
          display: flex;
          flex-direction: row;
          align-items: center;
          text-decoration: none;
          cursor: pointer;

          img {
            margin-right: 8px;
          }
        }

        .doc-el:hover {
          background-color: unset !important;
          color: #FFFFFF;
        }

        .doc-el:nth-child(even) {
          margin: 10px 0;
        }
      }

    }

  }
}
@import "bootstrap";
@import "header";
@import "footer";
@import "placeholder";
@import "main-section";
@import "early-access";
@import "feature";
@import "what-vidi";
@import "why-vidi";
@import "map";
@import "tariff";
@import "use-token";
@import "road-map";
@import "security";
@import "team";
@import "join";

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

//------------------------------------
//          Custom colors
//------------------------------------

$--ui-color-blue: #4482FF;
$--ui-color-blue-dark: #1849A9;
$--ui-color-blue-med: #92b4f6;
$--ui-color-blue-light: #EEF4FF;
$--ui-color-blue-bg: #e9f0fd;

$--ui-color-500: #172133;
$--ui-color-400: #57626D;
$--ui-color-300: #707A83;

$--ui-color-inversive-500: #ffffff;
$--ui-color-inversive-400: #f5f7fa;

$--ui-color-auth-background: #f8f8f8;

$--ui-color-black-light: #48556e;
$--ui-color-gray-dark: #8d95a5;

$--ui-color-gray: #d4d9e2;
$--ui-color-gray-light: #e6e9f0;
$--ui-color-grey-med: #8D95A5;


$--ui-color-green: #3AC267;
$--ui-color-green-dark: #268045;
$--ui-color-green-light: #93DDAC;
$--ui-color-green-bg: rgba(223, 250, 232, 0.3);


$--ui-color-red: #DF2059;

// -----------------------------------
//              Helper
//-----------------------------------

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}

$encoding-reference: (
                ('<', '%3C'),
                ('>', '%3E'),
                ('"', '\''),
                ('#', '%23'),
                ('&', '%26')
);

@function svg-encode($svg) {
  @each $char, $encoded in $encoding-reference {
    $svg: str-replace($svg, $char, $encoded);
  }
  @return 'data:image/svg+xml,' + $svg;
}

.text-align-center {
  text-align: center;
}

.mt-40 {
  margin-top: 40px;
}

.mt-20 {
  margin-top: 20px;
}

//------------------------------------
//             Shortcuts
//------------------------------------

%bold-heading {
  font-style: normal;
  font-weight: 600;
}

%bold-medium {
  font-style: normal;
  font-weight: 500;
}


%text-bold {
  font-style: normal;
  font-weight: 600;
}

%text-regular {
  font-style: normal;
  font-weight: 400;
}

%one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

%bold-extra {
  font-style: normal;
  font-weight: 700;
}

//------------------------------------
//          Service Styles
//------------------------------------

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

html {
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Montserrat', sans-serif, sans-serif;
  padding: 0;
  margin: 0;
  font-size: 18px;
  background-image: url("../images/background_main.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: 100px;
  background-position-x: center;
}

p {
  padding: 0;
  margin: 0;
}

.landing {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

//-----------------------------------
//            Typography
//-----------------------------------

.h2 {
  @extend %bold-heading;
  font-size: 1.67em;
  line-height: 133%;
}

.h3 {
  @extend %bold-heading;
  font-size: 1.11em;
  line-height: 140%;
}

.h4 {
  @extend %bold-heading;
  font-size: 1.11em;
  line-height: 1.55em;
}

.h5 {
  @extend %bold-medium;
  font-size: 1em;
  line-height: 1.33em;
}

.body {

  &-13 {
    @extend %text-regular;
    font-size: 13px;
    line-height: 20px;

    &-bold {
      @extend %text-bold;
      font-size: 13px;
      line-height: 20px;
    }
  }

  &-15 {
    @extend %text-regular;
    font-size: 15px;
    line-height: 24px;

    &-bold {
      @extend %text-bold;
      font-size: 15px;
      line-height: 24px;
    }
  }

  &-18 {
    @extend %text-regular;
    font-size: 18px;
    line-height: 28px;

    &-bold {
      @extend %text-bold;
      font-size: 18px;
      line-height: 28px;
    }
  }

  &-32 {
    @extend %text-regular;
    font-size: 32px;
    line-height: 38.5px;

    &-bold {
      @extend %bold-extra;
      font-size: 32px;
      line-height: 38.5px;
    }
  }

    &-48 {
    @extend %text-regular;
    font-size: 48px;
    line-height: 59px;

    &-bold {
      @extend %text-bold;
      font-size: 48px;
      line-height: 59px;
    }
  }
}

.text-64-extra-bold {
  @extend %bold-extra;
  font-size: 64px;
  line-height: 78.02px;
}

.color-blue-med {
  color: #92B4F6;
}

//-----------------------------------
//              Colors
//-----------------------------------

.color {
  &-blue {
    color: $--ui-color-blue;
  }

  &-black {
    color: #222222;
  }

  &-text-primary {
    color: #2E2E3A;
  }

  &-300 {
    color: $--ui-color-300;
  }

  &-400 {
    color: $--ui-color-400;
  }

  &-500 {
    color: $--ui-color-500;
  }

  &-green {
    color: #268045;
  }

  &-inversive-500 {
    color: $--ui-color-inversive-500;
  }
}

// -----------------------------------
//              Button
//-----------------------------------

.btn__default-primary-rounded, .btn__default-light-rounded {
  @extend %one-line;
  background: $--ui-color-inversive-500;
  border: 1px solid $--ui-color-inversive-500;
  border-radius: 39px;
  padding: 12px 24px;

  .btn__default-text {
    color: $--ui-color-blue;
  }
}

.btn__default-primary {
  @extend %one-line;
  background: $--ui-color-blue;
  border: 1px solid $--ui-color-blue;
  border-radius: 8px;
  padding: 12px 24px;
  box-shadow: 0 15px 50px rgba(80, 134, 224, 0.2);
  text-decoration: none;
  cursor: pointer;

  .btn__default-text {
    color: $--ui-color-inversive-500;
  }
}

.btn__default-primary-light {
  @extend %one-line;
  background: #FFFFFF;
  border: 1px solid #EEF4FF;
  border-radius: 8px;
  padding: 12px 24px;
  box-shadow: 0 15px 50px rgba(80, 134, 224, 0.2);
  text-decoration: none;
  cursor: pointer;

  .btn__default-text {
    color: $--ui-color-blue;
  }
}

.btn__default-primary:hover {
  background-color: #4482FF;
  color: transparent;
}

// -----------------------------------
//              Modal
//------------------------------------

.blur-portal {
  display: none;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(149, 166, 195, 0);
  backdrop-filter: blur(0px);
  box-shadow: none;
  transition: .3s all ease-in-out;
  opacity: 0;
  &.active {
    display: flex;
  }

  a, button {
    text-decoration: none;
    cursor: pointer;
    width: 310px;
    border-radius: 39px;

    span {
      margin: auto;
    }
  }

  a:hover {
    background-color: $--ui-color-blue;
    color: transparent;
  }


  &.animate {
    opacity: 1;
    background: rgba(149, 166, 195, 0.2);
    backdrop-filter: blur(10px);
  }
}

.blur-modal {
  width: 620px;
  background-color: white;
  z-index: 1000;
  border-radius: 30px;
  padding: 56px;
  box-shadow: 0 15px 50px 0 #5086E033;
  position: relative;

  .success-pay-modal-body {
    img {
      margin-bottom: 20px;
    }
  }

  p {
    text-align: center;
  }

  p:last-child {
    margin-top: 30px;
  }

  .modal__close {
    position: absolute;
    top: 32px;
    right: 32px;
    height: 48px;
    width: 48px;
    border-radius: 24px;
    border: 1px solid $--ui-color-blue-light;
  }
}

// -----------------------------------
//              Mobile
//-----------------------------------

@media (max-width: 1600px) {
  .road-map-section .header-section .scroll-btn {
    display: block;
  }

}

@media (max-width: 1400px) {

  .feature-section .feature-content {
    justify-content: space-around!important;
  }

  .why-vidi-section .why-vidi-content .for-business .right-section, .why-vidi-section .why-vidi-content .for-people .right-section {
    width: 500px;
  }

  .why-vidi-section .why-vidi-content .for-business img, .why-vidi-section .why-vidi-content .for-people img {
    width: 480px;
    height: 389px;
  }

  .security-section .security-content .security-content .left-section {
    height: 530px;
    margin: auto;
  }

  .security-section .security-content .security-content .right-section .row-card {
    width: 500px;
    padding: 40px 30px;
  }

  .team-section .team-content .team-body .right-section {
    width: 724px;
  }
}

@media (max-width: 1200px) {

  .header {
    .desktop {
      a {
        display: none;
      }

      a:first-child, a:last-child {
        display: block;
      }

      .language_container {
        display: none;
      }

      .btn__default-primary {
        display: none!important;
      }
    }

    .navigation-menu {
      display: block;
    }
  }

  .why-vidi-section .why-vidi-content .header-section {
    flex-direction: column;

    .switcher {
      margin-top: 20px;
    }
  }

  .why-vidi-section .why-vidi-content .for-business, .why-vidi-section .why-vidi-content .for-people {
    justify-content: center;
  }

  .why-vidi-section .why-vidi-content .for-business img, .why-vidi-section .why-vidi-content .for-people img {
    width: 526px;
    height: 389px;
  }

  .why-vidi-section .why-vidi-content .for-business .right-section, .why-vidi-section .why-vidi-content .for-people .right-section {
    width: 746px;
    align-items: center;

    a {
      margin: auto;
    }
  }

  .map-section img {
    width: 768px;
  }

  .tariff-section .tariff-content .flex-section .left-section .tariff_mini-card {
    width: 500px;
  }

  .security-section .security-content .security-content {
    flex-direction: column;
  }

  .security-section .security-content .security-content .right-section {
    align-items: center;
    margin-top: 20px;

    .row-card {
      width: 527px;
    }
  }

  .team-section {
    padding: 100px 0 100px;
  }

  .team-section .team-content .team-body {
    justify-content: space-around;
  }

  .team-section .team-content .team-body .right-section {
    flex-direction: column;
    width: 426px;

    .first-column, .second-column {
      gap: 20px;
    }

    .second-column {
      margin-top: 20px;
    }
  }

  .team-section .team-content .team-body .left-section {
    justify-content: center;
  }
}

// -----------------------------------
//          breakpoint: LG - ≥992px
//------------------------------------
@include media-breakpoint-down(lg) {

  .tariff-section .tariff-content .flex-section {
    justify-content: center;
  }



  .tariff-content {
    .left-section {
      display: none;
    }

    .right-section {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .tariff-card {
        display: block!important;
      }
    }
  }

  .team-section .team-content .team-body .right-section {
    width: 290px;
  }

  .use-token-section .use-token-content .flex-container {

    flex-direction: column;

    img {
      width: 400px;
      margin: auto;
    }
  }

  .security-section .security-content .security-content .left-section img {
    position: absolute;
    top: 306px;
    right: -90px;
    width: 410px;
  }


}

// -----------------------------------
//          breakpoint: md - ≥768px
//------------------------------------
@include media-breakpoint-down(md) {

  body {
    background-image: url("../images/main-mobile-bg.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: 0;
    background-position-x: center;
  }

  .main-section {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .body-48-bold {
    font-size: 24px;
    line-height: 32px;
  }

  .body-32-bold {
    font-size: 20px;
    line-height: 28px;
  }

  .h4 {
    font-size: 15px;
    line-height: 24px;
  }

  .body-18-bold {
    font-size: 15px;
    line-height: 24px;
  }

  .body-15-bold {
    font-size: 13px;
    line-height: 20px;
  }

  .early-access {
    padding: 90px 0;
    background-image: url("../images/early-mobile-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: center;
  }

  .feature-section {
    padding: 90px auto;
  }

  .what-vidi-section {
    padding: 90px 0;
    background-image: url('../images/what_vidi_bg-mobile.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: center;
  }

  .why-vidi-section {
    padding: 90px 0;
  }

  .map-section {
    padding: 30px 0 30px;
  }

  .map-section img {
    width: 576px;
  }

  .team-section .team-content .team-body {
    flex-direction: column;

    .left-section {
      flex-direction: row;
      width: 343px;

      img {
        width: 90px;
      }

      .info-wrapper {
        margin-left: 30px;
        .blue-section {
          flex-direction: row;

          .h3 {
            margin-right: 82.5px;
          }

          a {
            width: 27px;
            height: 27px;
            img {
              width: 21px;
              height: 21px;
            }
          }
        }
      }


    }
  }

  .team-section .team-content .team-body .right-section {
    width: 343px;
    margin: auto;
  }

  .team-section .team-content .team-body .team-item {
    img {
      width: 90px;
    }
  }

  .team-section .team-content .team-body .left-section {
    justify-content: start;
    margin: auto auto 30px auto;
  }

  .team-section .team-content .team-body .left-section .blue-section p.h3 {
    margin-bottom: 0;
  }

  .team-section .team-content .team-body .right-section .first-column, .team-section .team-content .team-body .right-section .second-column {
    gap: 30px;
  }

  .join-section {
    padding: 90px 0
  }

  .security-section .security-content .security-content .left-section {
    padding: 30px 16px 59px;
    background: #92B4F6;
    border-radius: 10px;
    width: 527px;
    position: relative;
  }

  .security-section .security-content .security-content .left-section {
    height: 339px;
    margin: auto;
  }

  .security-section .security-content .security-content .left-section img {
    position: absolute;
    top: 183px;
    right: -27px;
    width: 320px;
  }

  .security-section .security-content .security-content .left-section {
    width: 527px;
  }

  .security-section .security-content .security-content .right-section .row-card {
    width: 527px;
  }


  .main-placeholder .main-content .body-48-bold {
    margin: 40px 0 8px;
  }

  .main-placeholder .main-content a.btn__default-primary {
    height: 48px;
    width: 343px;
  }

  .main-placeholder .main-content .body-15-bold {
    padding: 0 16px;
  }

  .main-placeholder {
    padding: 90px 0;
  }
}

@media (max-width: 590px) {
  .security-section .security-content .security-content .left-section img {
    position: absolute;
    top: 183px;
    right: -12px;
    width: 287px;
  }


}

@media (max-width: 576px) {
  .h2 {
    font-size: 24px;
    line-height: 32px;
  }

  .h5 {
    font-size: 15px;
    line-height: 24px;
  }

  .body-15 {
    font-size: 13px;
    line-height: 20px;
  }

  .security-section .security-content .security-content .left-section {
    width: 343px;
  }

  .security-section .security-content .security-content .right-section .row-card {
    width: 343px;
  }

  .map-section img {
    width: 375px;
  }

  .what-vidi-section .container img {
    width: 343px;
    height: 321px;
  }

  // adaptive

  .why-vidi-section .why-vidi-content .for-business img, .why-vidi-section .why-vidi-content .for-people img {
    width: 343px;
    height: 254px;
  }

  .tariff-section .tariff-content .flex-section .right-section .tariff-card {
    width: auto;
    height: 677px;
    padding: 24px 16px;
  }

  .tariff-section .tariff-content .flex-section .right-section .tariff-card .property-container .tariff-property .line-separator {
    display: none;
  }

  .use-token-section .use-token-content .flex-container img {
    width: 343px;
    height: 337.59px;

  }

  .big {
    display: none;
  }

  .security-section .security-content .security-content .left-section .small {
    display: block;
  }

  .security-section .security-content .security-content .left-section img {
    position: absolute;
    top: 228px;
    right: 15px;
    width: 180px;
  }

  .main-content {
    .body-18-bold {
      text-align: center;
    }
    .body-15-bold {
      text-align: center;
    }
  }

  .main-section .main-content .arrow-button {
    justify-content: center;
  }

  .early-access .content .btn-container {
    flex-direction: column;
    align-items: center;

    a {
      width: 100%;
    }

    a:first-child {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
}
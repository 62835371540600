.road-map-section {
  padding: 100px 0;
  background: #F8FBFE;

  .header-section {
    margin-bottom: 60px;
    p.body-48-bold {
      margin-bottom: 20px;
    }

    .scroll-btn {
      display: none;
      img {
        margin: auto;
      }

      .left-arrow, .right-arrow {
        width: 36px;
        height: 36px;
        border-radius: 8px;
        border: 1px solid #5264F9;
        background-color: #F8FBFE;

        &.left-arrow img {
          transform: rotate(180deg);
        }
      }

      .left-arrow-disable, .right-arrow-disable {
        width: 36px;
        height: 36px;
        border-radius: 8px;
        border: 1px solid #D4D9E2;
        background-color: #F8FBFE;

        &.right-arrow-disable img {
          transform: rotate(180deg);
        }
      }
    }
  }

  .scroll-wrapper {
    width: 90%;
    margin: auto;
    overflow-x: hidden;
    touch-action: auto;
    scroll-behavior: smooth;
    .road-map-container {
      margin: auto;
      width: 1600px;

      .first-line {
        margin-bottom: 20px;
      }
    }
  }



  .description-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 0;
    width: 1312px;

    .column-item {
      width: 306px;
    }

  }
}
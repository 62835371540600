.main-section {
  margin-top: 60px;
  margin-bottom: 160px;

  .main-content {
    padding: 69px 0 46px ;

    .body-48-bold {
      margin: 40px auto 16px;
    }

    .body-15-bold {
      margin: 40px auto;
    }

    .arrow-button {
      width: 48px;
      height: 48px;
    }
  }
}
.feature-section {
  padding: 100px 0;

  .feature-content {
    gap: 50px;

    .card-item {
      box-shadow: 0 15px 50px rgba(80, 134, 224, 0.2);
      border-radius: 16px;
      width: 387px;
      height: 228.14px;
      align-items: center;


      img {
        margin-bottom: 32px;
      }

      p {
        padding: 0 24px;
        text-align: center;
      }
    }
  }
}
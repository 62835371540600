.main-placeholder {
  padding: 129px 0 100px;

  .main-content {
    width: auto;
    max-width: 775px;
    margin: auto;

    .body-48-bold {
      margin: 40px 0 16px;
    }

    .body-15-bold {
      margin: 40px 0;
    }

    a.btn__default-primary {
      height: 48px;
      width: 356px;

      span {
        margin: auto;
      }
    }

  }
}
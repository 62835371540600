.use-token-section {
  padding: 40px 0;
  margin-bottom: 100px;

  .use-token-content {

    .flex-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;


      .left-section {
        margin-top: 60px;
        .body-48-bold {
          margin-bottom: 20px;
        }

        p.body-18-bold.color-300 {
          margin-bottom: 25px;
        }

        ul.body-18-bold.color-300:last-child {
          margin-bottom: 15px;
        }
      }
    }
  }
}
.why-vidi-section {
  padding: 100px 0;

  .why-vidi-content {
    flex-direction: row;
    justify-content: space-between;

    .header-section {
      justify-content: space-between;
      margin-bottom: 60px;

      .switcher {
        button {
          border: none;
          background: transparent;
          color: #707A83;
          .blue-line {
            height: 5px;
          }

          &.active {
            color: #4482FF;

            .blue-line {
              background-color: #4482FF;
            }
          }
        }


      }
    }

    .for-business, .for-people {
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;

      img {
        width: 526px;
        height: 389px;
      }

      .right-section {
        width: 746px;

        ul {
          margin: 20px 0;
        }

        a {
          width: 240px;

          span {
            margin: auto;
          }
        }
      }
    }
  }
}
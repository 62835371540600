.team-section {
  padding: 192px 0 100px;

  .team-content {

    .team-body {
      margin-top: 60px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .left-section {
        display: flex;
        flex-direction: column;
        align-items: center;

        .name {
          margin-top: 20px;
          margin-bottom: 15px;
        }

        .blue-section {
          display: flex;
          flex-direction: column;
          align-items: center;

          p.h3 {
            margin-bottom: 20px;
          }
        }
      }

      .team-item {
        display: flex;
        flex-direction: row;
        align-items: center;

        .team-text {
          margin-left: 30px;
          display: flex;
          flex-direction: column;

          .h3 {
            margin-bottom: 15px;
          }

        }
      }

      .right-section {
        width: 859px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        .first-column, .second-column {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

      }
    }
  }
}
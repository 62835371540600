.early-access {
  background: #F8FBFE;
  padding: 144px 0;
  background-image: url("../images/early-access-bg-low.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;

  .content {
    max-width: 745px;

    .btn-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;

      margin-top: 40px;
      margin-bottom: 40px;

      a {
        width: 240px;

        span {
          margin: auto;
        }
      }

      a:first-child {
        margin-right: 56px;
      }
    }
  }
}